import { navigate } from 'gatsby-link'
import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Switch from 'rc-switch'
import 'rc-switch/assets/index.css'
import { Button } from '../../../components/Button/Button'
import Images from '../../../images'
import { useApiBase } from '../../../services/api'
import { chromeSendMessage } from '../../../services/chrome'
import { notifyToast, trackEvent, trackPage, useTranslationInternal } from '../../../services/helpers'
import { findLanguageByLanguageKey } from '../../../services/languagesData'
import '../../../styles/style.scss'
import { SingleField } from '../../Landing/Contact'
import { LanguageDropdown } from '../Auth/Auth'
import { StyledLink } from './commons'
import { LanguagesToLearnModal } from './LanguagesToLearnModal'
import { NativeLanguageModal } from './NativeLanguageModal'
import { ContentContainer, Divider, Header, HeaderBig, LearningHeader, SingleLanguageStyled } from './styled'
import { setRedirectTo } from '../../../services/localStorageHelpers'
import { DeleteAccountModal } from './DeleteAccountModal'
import { ReportAccountModal } from './ReportErrorModal'

export const Settings = () => {
  const api = useApiBase()
  const [isLoading, setIsLoading] = useState(true)
  const [isModalActive, setIsModalActive] = useState(false)
  const [isDeleteAccountModalActive, setIsDeleteAccountModalActive] = useState(false)
  const [isReportErrorModalActive, setIsReportErrorModalActive] = useState(false)
  const [onboardingNotFinished, setOnboardingNotFinished] = useState(false)
  const [isLanguagesToLearnModalVisible, setIsLanguagesToLearnModalVisible] = useState(false)
  const t = useTranslationInternal()
  const [formData, setFormData] = useState({})
  const [formChanges, setFormChanges] = useState({})
  const [useContextMenu, setUseContextMenu] = useState(false)
  const [instantTranslations, setInstantTranslations] = useState(false)
  const [extensionSettings, setExtensionSettings] = useState(null)

  const SETTINGS_FORM_FIELDS = [
    {
      name: 'native_language',
      label: t("settings.nativeLanguage"),
      placeholder: "",
      inputType: 'native_language',
      value: ''
    },
    {
      name: 'email',
      label: t("auth.email"),
      placeholder: t("auth.email.placeholder"),
      inputType: 'input',
      value: '',
      disabled: true,
    },
  ]
  useEffect(() => {
    const headersLocalStorage = JSON.parse(typeof window !== 'undefined' ? localStorage.getItem("underline") : "{}")
    if (!headersLocalStorage) {
      navigate("/app/login")
    }
    trackPage("Settings Page")
  }, [])

  const handleChangeField = (fieldName) => (fieldValue) => {
    setFormData({
      ...formData,
      [fieldName]: fieldValue.target.value,
    })
  }

  const sendContextMenuInformation = (useContextMenu) => {
    setUseContextMenu(useContextMenu)
    chromeSendMessage({
      type: 'useContextMenu',
      place: 'settings',
      value: useContextMenu,
    })
  }

  const sendInstantTranslations = (instantTranslations) => {
    setInstantTranslations(instantTranslations)
    chromeSendMessage({
      type: 'instantTranslations',
      place: 'settings',
      value: instantTranslations,
    })
  }

  const getUser = () => {
    api.getUser().then(response => {
      const { ok, data } = response
      if (ok) {
        setIsLoading(false)

        setExtensionSettings(response.data.extension_settings);
        if (!response.data.native_language && response.data.learning_languages.length === 0) {
          setOnboardingNotFinished(true)
        }
        const formattedData = {
          email: data.email,
          languagesToLearn: data.learning_languages,
          nativeLanguage: data.native_language,
          weeklyGoal: data.weekly_goal,
          id: data.id
        }

        const useContextMenuUser = data.extension_settings?.use_context_menu
        const instantTranslations = data.extension_settings?.instant_translations
        sendContextMenuInformation(!!useContextMenuUser)
        sendInstantTranslations(!!instantTranslations)

        chromeSendMessage({
          type: 'languagesToLearn',
          place: 'settings',
          languagesToLearn: response.data.learning_languages,
        })

        chromeSendMessage({
          type: 'nativeLanguage',
          place: 'settings',
          language: response.data.native_language,
        })

        setFormData(formattedData)
        setFormChanges(formattedData)
      } else {
        logout(false)
      }
    })
  }

  useEffect(() => {
    getUser()
  }, [])

  const setNativeLanguage = (language) => {
    setFormChanges({
      ...formData,
      nativeLanguage: language
    })
  }

  const setLanguagesToLearn = (languages) => {
    setFormChanges({
      ...formData,
      languagesToLearn: languages
    })
  }

  const saveChanges = (saveType) => () => {
    api.updateUser({
      learningLanguages: formChanges.languagesToLearn,
      nativeLanguage: formChanges.nativeLanguage,
      weeklyGoal: formChanges.weeklyGoal,
    }).then(response => {
      if (response.ok) {
        getUser()
        setIsLanguagesToLearnModalVisible(false)
        setIsModalActive(false)
        notifyToast("Zaktualizowano")
      } else {
        notifyToast("Wystąpił bład. Spróbuj odświezyć strone i spróbować ponownie", 'error')
      }
    })
  }

  const onCloseModal = () => {
    setIsModalActive(false)
    setIsLanguagesToLearnModalVisible(false)
    setIsDeleteAccountModalActive(false)
    setIsReportErrorModalActive(false)
    getUser()
  }

  const logout = (userForcesLogout) => {
    localStorage.removeItem("underline")
    
    if (userForcesLogout) {
      chromeSendMessage({ type: 'logout' })
    } else {
      setRedirectTo("/app/settings")
    }
    navigate(`/app/login`)
  }

  const updatePassword = () => {
    notifyToast(t("settings.availableSoon"), 'info')
  }

  const deleteAccount = () => {
    trackEvent("Account Deleted", )
    api.deleteUser().then(() =>{
      logout();
    });
  }

  const onUseContextChange = (e) => {
    sendContextMenuInformation(e)
    trackEvent("Use Context Menu", { context_menu: e })
    api.updateSettings({
      ...extensionSettings,
      use_context_menu: e,
      instant_translations: instantTranslations,
    })
  }

  const onInstantTranslationChange = (e) => {
    sendInstantTranslations(e)
    trackEvent("All Translated Words Saved", {all_translated_words: e})
    api.updateSettings({
      ...extensionSettings,
      instant_translations: e,
      use_context_menu: useContextMenu
    })
  }

  return (
    <div style={{ background: '#FBFCFF', minHeight: '100vh', padding: 64 }}>
      <ToastContainer />

      <div className="container">
        <NativeLanguageModal
          closeModal={onCloseModal}
          isModalActive={isModalActive}
          formChanges={formChanges}
          saveChanges={saveChanges('native_language_modal')}
          setNativeLanguage={setNativeLanguage}
        />
        <LanguagesToLearnModal
          closeModal={onCloseModal}
          isModalActive={isLanguagesToLearnModalVisible}
          formChanges={formChanges}
          saveChanges={saveChanges('foreign_language_modal')}
          setLanguagesToLearn={setLanguagesToLearn}
        />
        <DeleteAccountModal
          closeModal={onCloseModal}
          isModalActive={isDeleteAccountModalActive}
          deleteAccount={deleteAccount}
        />
        <ReportAccountModal
          closeModal={onCloseModal}
          isModalActive={isReportErrorModalActive}
          email={formData.email}
        />
        <div className="columns">
          <div className="column is-justify-content-flex-start is-flex">
            <HeaderBig style={{ fontSize: 21 }}>
              {t('settings.header')}
            </HeaderBig>
          </div>
          <div className="column is-justify-content-flex-end is-flex" style={{ alignItems: 'center', }}>
            <img
              src={Images.logoYellowWhite}
              srcSet={`${Images.logoYellowWhite3x} 3x, ${Images.logoYellowWhite2x} 2x`}
              style={{ width: 80, marginRight: 20 }}
            />
            <div>
              <LanguageDropdown/>
            </div>
          </div>
        </div>
        <ContentContainer>
          <div className="columns is-flex is-justify-content-center mt-0">
            <div className="column is-flex is-justify-content-flex-start is-flex-direction-column	" style={{ maxWidth: 400 }}>
              {isLoading && <div className="mt-4">Loading...</div>}
              {onboardingNotFinished && <div className="is-flex" style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Button onClick={() => navigate("/app/intro")} style={{ marginTop: 32, width: '50%' }} disabled={formData['name'] === ""}>
                  {t("settings.finishOnboarding")}
                </Button>
                <p className="mt-4 mb-4" style={{ fontSize: 13, color: '#1E222D' }}>
                  {t("settings.or")}
                </p>
                <StyledLink onClick={() => logout(true)} button label={t("settings.logout")} />
              </div>
              }
              {!isLoading && !onboardingNotFinished &&
                <>
                  <Header className="has-text-left">
                    {t("settings.yourProfile")}
                  </Header>

                  <div className="mt-4">
                    {SETTINGS_FORM_FIELDS.map(item => {
                      if (item.inputType === "native_language") {
                        return (
                          <div style={{ marginBottom: 24 }}>
                            <label className="label has-text-left " style={{ fontSize: "11px", lineHeight: '170%' }}>
                              {t("settings.nativeLanguage")}
                            </label>
                            <SingleLanguageStyled
                              item={findLanguageByLanguageKey(formData.nativeLanguage)}
                              onClick={() => setIsModalActive(true)}
                            />
                          </div>
                        )
                      }
                      return (
                        <SingleField
                          isErrorVisible={true}
                          inputProps={{
                            disabled: item.disabled,
                            name: item.name,
                            label: item.label,
                            placeholder: item.placeholder,
                            inputType: item.inputType,
                            handleChangeField: handleChangeField(item.name),
                            value: formData[item.name]
                          }}
                        />
                      )
                    })}
                  </div>

                  <StyledLink button onClick={updatePassword} label={t('settings.changePassword')}/>
                  <StyledLink
                    button
                    onClick={() => setIsDeleteAccountModalActive(true)}
                    label={t('settings.deleteAccount')}
                    style={{ color: '#FF4F64', fontWeight: 600, marginTop: 24 }}
                  />

                  <Divider/>

                  <LearningHeader>
                    {t('settings.learning')}
                  </LearningHeader>

                  <div style={{ marginTop: 16 }}>
                    <label className="label has-text-left "
                           style={{ fontSize: '11px', lineHeight: '170%' }}>{t('settings.languagesToLearn')}</label>
                    {formData.languagesToLearn?.map(item => {
                      return (<SingleLanguageStyled
                        key={item}
                        item={findLanguageByLanguageKey(item)}
                        onClick={() => setIsLanguagesToLearnModalVisible(true)}
                      />)
                    })}
                  </div>
                  <div style={{ marginTop: 16 }}>
                    <StyledLink
                      button
                      onClick={() => setIsLanguagesToLearnModalVisible(true)}
                      label={t("settings.editForeignLanguages")}
                    />
                  </div>
                  <div style={{ marginTop: 32 }}>
                    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <p style={{ fontFamily: 'Poppins', color: '#1E222D', fontSize: 13, marginRight: 20 }}>
                        {t("settings.contextMenu")}
                      </p>
                      <div>
                        <Switch
                          className="underline"
                          onChange={onUseContextChange}
                          disabled={false}
                          checked={useContextMenu}
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: 16 }}>
                      <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <p style={{ fontFamily: 'Poppins', color: '#1E222D', fontSize: 13, marginRight: 20 }}>
                          {t("settings.addInstantTranslations")}
                        </p>
                        <div>
                          <Switch
                            className="underline"
                            onChange={onInstantTranslationChange}
                            disabled={false}
                            checked={instantTranslations}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider/>
                  <StyledLink
                    button
                    onClick={() => setIsReportErrorModalActive(true)}
                    label={t('settings.reportError')}
                    style={{ color: '#6297FF', fontWeight: 600 }}
                  />
                  <Divider/>
                  <Button
                    style={{ marginTop: 8, width: '40%' }}
                    onClick={logout}
                  >
                    {t('settings.logout')}
                  </Button>
                </>}
            </div>
          </div>
        </ContentContainer>
      </div>
    </div>
  )
}
