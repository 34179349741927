import styled from 'styled-components'
import { SingleLanguage } from '../../../components/SingleLanguage/SingleLanguage'

export const SingleLanguageStyled = styled(SingleLanguage)`
  background-color: #F2F5FC!important;
  border: none!important;
  height: 44px!important;
  border: 1px solid transparent!important;
`

export const LearningHeader = styled.p`
  font-size: 15px!important;
  color: #1E222D!important;
  font-weight: bold!important;
  margin-top: 8px!important;
`
export const DeleteAccountDescriptionText = styled.p`
  font-size: 13px;
  color: #566280;
  font-weight: 400;
  margin-top: 24px!important;
`

export const Header = styled.p`
  font-size: 15px!important;
  color: #1E222D!important;
  font-weight: bold!important;
  margin-top: 32px!important;
`

export const ModalHeader = styled.p`
  margin-bottom: 8px!important;
  font-size: 21px!important;
  color: #1E222D!important;
  font-weight: bold!important;
  margin-top: 32px!important;
`

export const HeaderBig = styled.p`
  font-size: 21px!important;
  color: #1E222D!important;
  font-weight: bold!important;
  margin-top: 32px!important;
`


export const ContentContainer = styled.div`
background: white!important;
padding: 0 0 60px 0!important;
margin: 0 0 200px 0!important;
border-Radius: 8px!important;
box-shadow: 0px 0px 16px rgba(152, 166, 199, 0.08)!important;
`

export const Divider = styled.hr`
  background: #D5DEF1!important;
  height: 1px!important;
`