import React, { useState } from 'react'
import { Button } from '../../../components/Button/Button'
import { useTranslationInternal } from '../../../services/helpers'
import '../../../styles/style.scss'
import { StyledLink } from './commons'
import { ModalHeader } from './styled'
import { SingleField } from '../../Landing/Contact'

const FORM_URL = 'https://logs.getunderline.io/tracklog'

export const ReportAccountModal = (props) => {
  const {
    closeModal,
    isModalActive,
    email
  } = props
  const t = useTranslationInternal()
  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const [errorValue, setErrorValue] = useState('')

  function onErrorValueChange (value) {
    setIsErrorVisible(false)
    setErrorValue(value)
  }

  const handleSubmit = () => {
    if (!email || !errorValue || errorValue.length === 0) {
      setIsErrorVisible(true)
    } else {
      setIsErrorVisible(false)
      const reqBody = {
        data: errorValue,
        user_mail: email,
      }
      fetch(FORM_URL, {
        method: 'POST',
        body: JSON.stringify(reqBody),
        headers: {
          'Content-type': 'application/json',
          'Accept': 'application/json'
        }
      }).then(res => {
        if (res.status === 200 || res.status === 201) {
          closeModal()
        }
      })
    }
  }

  return (
    <div className={`modal ${isModalActive && 'is-active'}`}>
      <div className="modal-background" onClick={() => closeModal()}></div>
      <div className="modal-content" style={{ maxWidth: 512 }}>
        <div className="box px-6">
          <article className="media">
            <div className="media-content">
              <div className="content is-flex is-flex-direction-column">
                <ModalHeader className="has-text-left">
                  {t('reportError.header')}
                </ModalHeader>
                <SingleField
                  style={{ marginTop: 24 }}
                  inputProps={{
                    onBlur: () => {},
                    name: 'error',
                    label: t('reportError.description'),
                    placeholder: t('reportError.placeholder'),
                    inputType: 'textarea',
                    handleChangeField: onErrorValueChange,
                    value: errorValue
                  }}
                />
                {isErrorVisible &&
                <div style={{ color: '#FF4F64', fontSize: 11, fontWeight: 500 }}>
                  {t('reportError.error')}
                </div>}
              </div>
              <div className="is-flex is-align-items-center mt-5 mb-5">
                <StyledLink
                  button
                  onClick={() => closeModal()}
                  label={t('settings.cancel')}
                  className="mr-4"/>
                <Button
                  onClick={handleSubmit}
                >
                  {t('reportError.header')}
                </Button>
              </div>
            </div>
          </article>
        </div>
      </div>
      <button onClick={() => closeModal()} className="modal-close is-large" aria-label="close"></button>
    </div>
  )
}