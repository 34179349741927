import React from 'react'
import { Button } from '../../../components/Button/Button'
import { useTranslationInternal } from '../../../services/helpers'
import '../../../styles/style.scss'
import { StyledLink } from './commons'
import { DeleteAccountDescriptionText, ModalHeader } from './styled'

export const DeleteAccountModal = (props) => {
  const {
    closeModal,
    deleteAccount,
    isModalActive,
  } = props
  const t = useTranslationInternal()
  return (
    <div className={`modal ${isModalActive && 'is-active'}`}>
      <div className="modal-background" onClick={() => closeModal()}></div>
      <div className="modal-content" style={{ maxWidth: 512 }}>
        <div className="box px-6">
          <article className="media">
            <div className="media-content">
              <div className="content is-flex is-flex-direction-column">
                <ModalHeader className="has-text-left">
                  {t('deleteAccount.header')}
                </ModalHeader>
                <DeleteAccountDescriptionText>{t('deleteAccount.description')}</DeleteAccountDescriptionText>
              </div>
              <div className="is-flex is-align-items-center mt-5 mb-5">
                <StyledLink
                  button
                  onClick={deleteAccount}
                  label={t('deleteAccount.header')}
                  className="mr-4"
                  style={{ color: '#FF4F64' }}
                />
                <Button
                  onClick={() => closeModal()}
                >
                  {t('deleteAccount.disagree')}
                </Button>
              </div>
            </div>

          </article>
        </div>
      </div>
      <button onClick={() => closeModal()} className="modal-close is-large" aria-label="close"></button>
    </div>
  )
}