import { Settings } from '../../containers/WebApp/Settings/Settings'

export default Settings

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`